import React, { useEffect, useRef, useState } from "react";
import { Paginator } from "primereact/paginator";
import { AuditTable } from "./AuditTable";
import { Panel } from "./_slidePanel/Panel";
import { AuditPanelContain } from "./AuditPanelContain";
import { DetailPanelContain } from "./detail/DetailPanelContain";
import { useAuditStore } from "../store/auditStore";
import { useFetchAudit, useFetchAuditById } from "../hooks/useAudit";
import { viewTypes } from "../common/types/views.type";
import auditService from "../services/audit.service";

export default function AuditAbm() {
  const { vnAuditorias: viewName, vfVerAuditorias: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");

  const { auditId, setAuditId, audit, setAudit, cleanAll } = useAuditStore(
    (state) => state
  );

  const [isOpen, setIsOpen] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);

  const initParams = {
    size: 10,
    page: 1,
    sortBy: null,
    direction: null,
    name: "",
    active: "",
    userId: localStorage.getItem("user"),
  };

  const getDirection = (direction) => (direction === 1 ? "ASC" : "DESC");

  const buildFilterAudit = (params) => ({
    ...params,
    direction: lazyParams.direction ? getDirection(lazyParams.direction) : null,
  });

  const [lazyParams, setLazyParams] = useState(initParams);
  const [filterAudit, setFilterAudit] = useState(buildFilterAudit(initParams));

  useEffect(() => {
    setFilterAudit(buildFilterAudit(lazyParams));
  }, [lazyParams]);

  const fetchAudit = useFetchAudit(filterAudit);

  useEffect(() => {
    if (fetchAudit.data) {
      setTotalRecords(fetchAudit.data.pagination.count);
      setCustomers(fetchAudit.data.audits);
    }
    if (fetchAudit.isLoading !== loading) {
      setLoading(fetchAudit.isLoading);
    }
  }, [fetchAudit.data, fetchAudit.isLoading]);

  /** */

  const [getAuditId, setGetAuditId] = useState(null);
  const {
    data: auditData,
    isLoading: auditLoading,
    error: errorAudit,
    refetch: refetchAudit,
  } = useFetchAuditById(getAuditId);

  useEffect(() => {
    if (auditData) {
      setAudit(auditData);
    }
  }, [auditData]);
  /** */

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        size: event.rows,
      };
    });
  };

  const closePanel = () => {
    setGetAuditId(null);
    cleanAll();
    setIsOpen(false);
  };

  const closePanelDetail = () => {
    setGetAuditId(null);
    setAuditId(null);
    setDetailOpen(false);
  };

  const openEdit = (_audit) => {
    setGetAuditId(_audit.id);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const openDetail = (_audit) => {
    setGetAuditId(_audit.id);
    setAuditId(_audit.id);
    setTimeout(() => {
      setDetailOpen(true);
    }, 8);
  };
  /** */

  const getFile = (_audit) => {
    auditService.downloadFile(
      _audit.id,
      _audit.name,
      userName,
      viewName,
      viewFunction
    );
  };
  /** */
  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.size}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const itemTemplate = (active) => {
    return (
      <span
        className={`customer-badge status-${
          active ? "qualified" : "unqualified"
        } text-status`}
      >
        {active ? "ACTIVO" : "INACTIVO"}
      </span>
    );
  };

  return (
    <div className="card">
      <AuditTable
        openEdit={openEdit}
        openDetail={openDetail}
        getFile={getFile}
        customers={customers}
        loading={loading}
        footerPage={footerPage}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        initParams={initParams}
      />
      <Panel isOpen={detailOpen} closePanel={closePanelDetail}>
        {auditId && audit && (
          <DetailPanelContain
            closePanel={closePanelDetail}
            itemTemplate={itemTemplate}
          />
        )}
      </Panel>

      <Panel isOpen={isOpen} closePanel={closePanel}>
        {audit && (
          <AuditPanelContain
            closePanel={closePanel}
            itemTemplate={itemTemplate}
          />
        )}
      </Panel>
    </div>
  );
}
