import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import auditAudioService from "../services/auditAudio.service";

const { vnAuditorias: viewName, vfVerAuditorias: viewFunction } = viewTypes;
const userName = localStorage.getItem("userName");

const fetchAudios = async (filter) => {
  const response = await auditAudioService.obtenerAudios(
    filter,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchAudios(filter) {
  return useQuery({
    queryKey: ["audio", filter],
    queryFn: () => fetchAudios(filter),
    refetchOnWindowFocus: false,
  });
}
/** */
