import React from "react";
import { Button, Dialog } from "primereact";

export const ConfirmationDialog = ({
  deleteDialog,
  hideDeleteDialog,
  deleteAudio,
}) => {
  const deleteAudioDialogFooter = (
    <>
      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteAudio}
      />
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteDialog}
      />
    </>
  );

  return (
    <Dialog
      visible={deleteDialog}
      header="Borrar audio"
      modal
      footer={deleteAudioDialogFooter}
      onHide={hideDeleteDialog}
      style={{ width: "400px", maxWidth: "95%" }}
    >
      <div className="flex align-items-center justify-content-center">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        <span>¿Estas seguro de que quieres eliminar el audio?</span>
      </div>
    </Dialog>
  );
};
