import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { ESTADOS, TableData } from "./uploaderTypes";
import { useAuditStore } from "../../store/auditStore";
import { LottieIcon } from "../LottieIcon";
import fireworks from "../../icons/fireworks.json";

export const DynamicTable = ({ variablesObligatorias, confirmDeleteAudio }) => {
  const { data, listos, total } = useAuditStore((state) => state);

  const initialFilters = variablesObligatorias.reduce((acc, key) => {
    acc[key] = { value: null, matchMode: FilterMatchMode.CONTAINS };
    return acc;
  }, {});

  const [filters, setFilters] = useState(initialFilters);

  // Usamos un estado para manejar la visibilidad de errores
  const [errorVisible, setErrorVisible] = useState({});

  useEffect(() => {
    // Inicializamos el objeto de errorVisible
    const initialErrorState = data.reduce((acc, row, index) => {
      if (row.error) {
        acc[row.index] = false; // Si hay un error, lo inicializamos en false
      }
      return acc;
    }, {});
    setErrorVisible(initialErrorState);
  }, [data]);

  const estadoTemplate = (rowData) => {
    switch (rowData.estado) {
      case ESTADOS.PENDIENTE:
        return <i className="pi pi-clock" style={{ color: "#2196F3" }}></i>;

      case ESTADOS.EN_ESPERA:
        return <i className="pi pi-history" style={{ color: "orange" }}></i>;

      case ESTADOS.EN_PROCESO:
        return (
          <i className="pi pi-spin pi-spinner" style={{ color: "orange" }}></i>
        );

      case ESTADOS.FINALIZADO:
        return <i className="pi pi-check" style={{ color: "#689F38" }}></i>;

      case ESTADOS.ERROR:
        return <i className="pi pi-times" style={{ color: "#D32F2F" }}></i>;

      default:
        return null;
    }
  };

  const actionBodyTemplate = (rowData: TableData) => {
    return (
      <div className="actions">
        {rowData.error !== "" ? (
          <div className="error-upload">
            {errorVisible[rowData.index] && (
              <div className="error">{rowData.error}</div>
            )}
            <button
              onClick={() => {
                setErrorVisible((prev) => {
                  const newErrorVisible = {};
                  Object.keys(prev).forEach((key) => {
                    newErrorVisible[key] = false;
                  });
                  newErrorVisible[rowData.index] = !prev[rowData.index];
                  return newErrorVisible;
                });
              }}
            >
              <i
                className="pi pi-question-circle"
                style={{ color: "orange" }}
              ></i>
            </button>
          </div>
        ) : (
          <button
            onClick={() => confirmDeleteAudio(rowData)}
            disabled={rowData.id === ""}
          >
            <i className="pi pi-trash" style={{ color: "#D32F2F" }}></i>
          </button>
        )}
      </div>
    );
  };

  const rowClass = (rowData: TableData) => {
    return {
      "row-pending": rowData.estado === ESTADOS.PENDIENTE,
    };
  };

  const onFilter = (e) => {
    const value = e.target.value;
    const field = e.target.id;
    setFilters({
      ...filters,
      [field]: { value, matchMode: FilterMatchMode.CONTAINS },
    });
  };

  const filterTemplate = (field) => {
    return (
      <div className="inner-filter">
        <i
          className="pi pi-search"
          style={{
            position: "absolute",
            left: "8px",
            top: "10px",
            color: "#2196F3",
          }}
        ></i>
        <InputText
          id={field}
          value={filters[field]?.value}
          onChange={onFilter}
          style={{ maxWidth: "300px", paddingLeft: "30px" }}
        />
      </div>
    );
  };

  const iconTemplate = () => {
    return (
      <div style={{ width: "60px", height: "40px" }}>
        {listos === total && total !== 0 && (
          <LottieIcon icon={fireworks} height={40} width={40} loop={true} />
        )}
      </div>
    );
  };

  const counterTemplate = () => {
    return (
      <div style={{ textAlign: "center" }}>
        {listos} / {total}
      </div>
    );
  };

  const dataWithIndex = data.map((item, index) => ({
    ...item,
    index,
  }));

  return (
    <>
      <DataTable
        value={dataWithIndex}
        filters={filters}
        filterDisplay="row"
        rowClassName={rowClass}
        responsiveLayout="scroll"
        stripedRows
        sortField="estado"
        sortOrder={1}
      >
        <Column
          body={actionBodyTemplate}
          alignHeader="center"
          style={{ width: "40px" }}
          bodyClassName={"centerRowData"}
          showFilterMenu={false}
          filter
          filterElement={iconTemplate}
        />
        <Column
          body={estadoTemplate}
          header="ESTADO"
          field="estado"
          style={{ width: "40px" }}
          alignHeader="center"
          bodyClassName={"centerRowData"}
          sortable
          showFilterMenu={false}
          filter
          filterElement={counterTemplate}
        />
        {Object.keys(data[0])
          .filter(
            (key) =>
              key !== "estado" &&
              key !== "id" &&
              key !== "error" &&
              key !== "index"
          )
          .map((key) => (
            <Column
              key={key}
              field={key}
              header={key}
              showFilterMenu={false}
              sortable={variablesObligatorias.includes(key)}
              filter={variablesObligatorias.includes(key)}
              body={(rowData) => rowData[key]}
              filterElement={
                variablesObligatorias.includes(key) ? filterTemplate(key) : null
              }
            />
          ))}
      </DataTable>
    </>
  );
};
