export const ESTADOS = {
  PENDIENTE: 5,
  EN_ESPERA: 4,
  EN_PROCESO: 3,
  FINALIZADO: 1,
  ERROR: 2,
};

export interface TableData {
  [key: string]: any;
  estado?: number;
}

export interface AudioObject {
  file: File;
  estado: number;
  row: any;
}
