import React, { useRef, useState } from "react";
import * as XLSX from "xlsx";
import { Toast } from "primereact/toast";
import { DynamicTable } from "./DynamicTable";
import { ESTADOS, TableData } from "./uploaderTypes";
import { AudioHandler } from "./AudioHandler";
import { useAuditStore } from "../../store/auditStore";
import { ConfirmationDialog } from "./ConfirmationDialog";
import auditAudioService from "../../services/auditAudio.service";
import { viewTypes } from "../../common/types/views.type";

const FileUploader = ({ uploadAudio, _variables }) => {
  const { data, setData, audios, setTotal, deleteRowData } = useAuditStore(
    (state) => state
  );

  const toast = useRef<Toast>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const result = event.target?.result;
      if (result instanceof ArrayBuffer) {
        const data = new Uint8Array(result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json<TableData>(worksheet);

        const firstRow = jsonData[0];
        const missingVariables = _variables.obligatorias.filter(
          (key) => !(key in firstRow)
        );

        if (
          jsonData.length === 0 ||
          (jsonData.length === 1 && Object.keys(jsonData[0]).length === 0)
        ) {
          toast.current?.show({
            severity: "error",
            summary: "Archivo no válido",
            detail: "El archivo está vacío.",
          });
          return;
        }

        if (missingVariables.length > 0) {
          toast.current?.show({
            severity: "error",
            summary: "Archivo no válido",
            detail: "Faltan campos obligatorios.",
          });
          return;
        }

        const filteredAdicionales = _variables.adicionales.filter((key) =>
          jsonData.some((row) => row[key])
        );

        const dataWithEstado = jsonData.map((item) => ({
          ..._variables.obligatorias.reduce((obj, key) => {
            obj[key] = item[key] || null;
            return obj;
          }, {}),
          ...filteredAdicionales.reduce((obj, key) => {
            obj[key] = item[key] || null;
            return obj;
          }, {}),
          estado: ESTADOS.PENDIENTE,
          id: "",
          error: "",
        }));

        const updatedData = mergeAudioState(dataWithEstado, audios);
        setTotal(updatedData.length);
        setData(updatedData);

        toast.current?.show({
          severity: "info",
          summary: "Cargar audios",
          detail: "Arrastra y suelta sobre la tabla.",
        });
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const mergeAudioState = (newData, audios) => {
    return newData.map((item) => {
      const audioMatch = audios.find((audio) => audio === item.NOMBRE_AUDIO);
      if (audioMatch) {
        return { ...item, estado: ESTADOS.EN_PROCESO };
      }
      return item;
    });
  };

  const onDropExcel = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files.length) {
      const file = e.dataTransfer.files[0];
      if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        handleFile(file);
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Archivo no válido",
          detail: "Debe ser un Excel.",
        });
      }
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  /** *********/
  const [audioRow, setAudioRow] = useState(null);

  const confirmDeleteAudio = (rowData) => {
    setAudioRow({ ...rowData });
    setDeleteDialog(true);
  };

  const hideDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const { vnAuditorias: viewName, vfVerAuditorias: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");

  const deleteAudio = () => {
    const _id = audioRow.id;
    auditAudioService
      .borrarAuditAudio(_id, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          hideDeleteDialog();

          toast.current.show({
            severity: "success",
            summary: "Borrar audio",
            detail: "Eliminado correctamente",
            life: 3000,
          });

          deleteRowData(_id);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Error en el borrado",
          detail: e.response.data.message,
          life: 3000,
        });
      });
  };

  return (
    <div className="file-uploader">
      <Toast ref={toast} />

      {data.length > 0 ? (
        <AudioHandler toast={toast} uploadAudio={uploadAudio} />
      ) : (
        <div
          className="overlay-drop"
          onDrop={onDropExcel}
          onDragOver={onDragOver}
        >
          Suelta el archivo Excel aquí
        </div>
      )}

      {data.length > 0 && (
        <DynamicTable
          variablesObligatorias={_variables.obligatorias}
          confirmDeleteAudio={confirmDeleteAudio}
        />
      )}

      <ConfirmationDialog
        deleteDialog={deleteDialog}
        hideDeleteDialog={hideDeleteDialog}
        deleteAudio={deleteAudio}
      />
    </div>
  );
};

export default FileUploader;
