import React, { useState } from "react";
import { Dropdown, InputText } from "primereact";
import { FilterWrapper } from "./_filterPanel/FilterWrapper";
import { styleEstado } from "../utils/option.util";

export const FilterPanel = ({ lazyParams, setLazyParams, initParams }) => {
  const initFilters = {
    name: "",
    active: "",
  };

  const optionsStatus = [
    {
      label: "Activo",
      value: true,
    },
    {
      label: "Inactivo",
      value: false,
    },
  ];

  const itemTemplate = (data) => {
    return (
      <span
        className={
          "customer-badge status-" +
          styleEstado(data.label === "Activo") +
          " text-status"
        }
      >
        {data.label}
      </span>
    );
  };

  const selectedTemplate = (option, props) => {
    if (option) {
      return (
        <span
          className={
            "customer-badge status-" +
            styleEstado(option.label === "Activo") +
            " text-status"
          }
        >
          {option.label}
        </span>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const [filters, setFilters] = useState(initFilters);

  const filtros = [
    {
      title: "Nombre",
      filtro: "name",
      input: (
        <InputText
          className="filter-text"
          value={filters.name}
          placeholder="Ingresa el nombre"
        />
      ),
    },
    {
      title: "Estado",
      filtro: "active",
      input: (
        <div className="select-container">
          <Dropdown
            value={lazyParams.active}
            options={optionsStatus}
            onChange={(e) => handleChange("active", e.value)}
            placeholder="Elige un estado"
            valueTemplate={selectedTemplate}
            itemTemplate={itemTemplate}
          />
        </div>
      ),
    },
  ];

  /** */

  const handleChange = (name, value) => {
    setFilters((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
    setLazyParams((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
  };

  return (
    <FilterWrapper
      lazyParams={lazyParams}
      setLazyParams={setLazyParams}
      initFilters={initFilters}
      filtros={filtros}
      initParams={initParams}
      filters={filters}
      setFilters={setFilters}
      handleChange={handleChange}
    />
  );
};
