import React, { useEffect, useState } from "react";
import { Button, Column, DataTable } from "primereact";
import { FilterPanel } from "./FilterPanel";

export const DetailTable = ({
  customers,
  loading,
  handleInfo,
  footerPage,
  lazyParams,
  setLazyParams,
  initParams,
}) => {
  const [jsonKeys, setJsonKeys] = useState([]);

  useEffect(() => {
    if (customers && customers.length > 0 && customers[0].jsonFieldValues) {
      try {
        const firstCustomerJson = JSON.parse(customers[0].jsonFieldValues);
        setJsonKeys(Object.keys(firstCustomerJson));
      } catch (error) {
        console.error("Error al parsear jsonFieldValues:", error);
      }
    }
  }, [customers]);

  const getJsonValue = (rowData, key) => {
    try {
      const jsonData = JSON.parse(rowData.jsonFieldValues);
      return jsonData[key];
    } catch (error) {
      console.error("Error al obtener jsonFieldValues:", error);
      return null;
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-info-circle"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => handleInfo(rowData)}
        />
      </div>
    );
  };

  return (
    <div>
      <FilterPanel
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        initParams={initParams}
      />

      <DataTable
        value={customers}
        responsiveLayout="scroll"
        dataKey="id"
        stripedRows
        sortOrder={lazyParams.direction}
        loading={loading}
        emptyMessage="Sin resultados"
        footer={footerPage}
      >
        <Column
          bodyStyle={{ textAlign: "end", justifyContent: "end" }}
          body={actionBodyTemplate}
          exportable={false}
        />
        {customers && customers.length > 0 && (
          <Column
            field="subStatus"
            showFilterMenu={false}
            header="SUB_ESTADO"
            alignHeader="center"
            bodyClassName={"centerRowData"}
          />
        )}
        {jsonKeys.map((key) => (
          <Column
            key={key}
            field={key}
            header={key}
            body={(rowData) => getJsonValue(rowData, key)}
            alignHeader="center"
            bodyClassName={"centerRowData"}
          />
        ))}
      </DataTable>
    </div>
  );
};
