import React from "react";
import { ESTADOS, AudioObject } from "./uploaderTypes";
import { useAuditStore } from "../../store/auditStore";

export const AudioHandler = ({ toast, uploadAudio }) => {
  const { data, audios, setAudios } = useAuditStore((state) => state);

  /** */
  const findMatch = (audioName) => {
    return data.find((item) =>
      Object.values(item).some((value) => value === audioName)
    );
  };

  const handleAudio = (files: File[]) => {
    const newAudios: AudioObject[] = files.map((file) => {
      const row = findMatch(file.name);

      return {
        file,
        estado: ESTADOS.EN_ESPERA,
        row,
      };
    });

    const updatedAudios = [...audios];

    newAudios.forEach((newAudio) => {
      if (!audios.some((audio) => audio.file.name === newAudio.file.name)) {
        updatedAudios.push(newAudio);
      }
    });

    uploadAudio(updatedAudios, newAudios);
    setAudios(updatedAudios);
  };

  const onDropAudio = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files.length) {
      const files: File[] = Array.from(e.dataTransfer.files);
      const audioFiles = files.filter((file: File) =>
        file.type.startsWith("audio/")
      );

      if (audioFiles.length > 0) {
        handleAudio(audioFiles);
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Archivo no válido",
          detail: "Debe ser un archivo de audio.",
        });
      }
    }
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <div
      className="overlay-drop audios"
      onDrop={onDropAudio}
      onDragOver={onDragOver}
    ></div>
  );
};
