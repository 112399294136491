import React, { useState } from "react";
import { InputText } from "primereact";
import { FilterWrapper } from "../_filterPanel/FilterWrapper";

export const FilterPanel = ({ lazyParams, setLazyParams, initParams }) => {
  const initFilters = {
    fileName: "",
  };

  const [filters, setFilters] = useState(initFilters);

  const filtros = [
    {
      title: "Nombre audio",
      filtro: "fileName",
      input: (
        <InputText
          className="filter-text"
          value={filters.fileName}
          placeholder="Ingresa el nombre"
        />
      ),
    },
  ];

  /** */

  const handleChange = (name, value) => {
    setFilters((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
    setLazyParams((prevEstado) => ({
      ...prevEstado,
      [name]: value,
    }));
  };

  return (
    <FilterWrapper
      lazyParams={lazyParams}
      setLazyParams={setLazyParams}
      initFilters={initFilters}
      filtros={filtros}
      initParams={initParams}
      filters={filters}
      setFilters={setFilters}
      handleChange={handleChange}
    />
  );
};
