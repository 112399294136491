import React, { useEffect, useState } from "react";
import { Button, Dialog } from "primereact";

export const ConfirmationProcess = ({
  processDialog,
  hideProcessDialog,
  handleBorrar,
  handleProcesar,
  processAction,
  updatedAudios,
}) => {
  const [summary, setSummary] = useState({
    finalizados: 0,
    errores: 0,
  });

  const getAudiosSummary = (audios) => {
    const _summary = {
      finalizados: 0,
      errores: 0,
    };
    audios.forEach((audio) => {
      if (audio.estado === 1) {
        _summary.finalizados++;
      } else if (audio.estado === 2) {
        _summary.errores++;
      }
    });
    return _summary;
  };

  useEffect(() => {
    setSummary(getAudiosSummary(updatedAudios));
  }, [updatedAudios]);

  const processAudioDialogFooter = (
    <>
      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() =>
          processAction && processAction === "procesar"
            ? handleProcesar()
            : handleBorrar()
        }
      />
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => hideProcessDialog()}
      />
    </>
  );

  return (
    <Dialog
      visible={processDialog}
      header="Administrar audios"
      modal
      footer={processAudioDialogFooter}
      onHide={hideProcessDialog}
      style={{ width: "400px", maxWidth: "95%" }}
    >
      <div className="flex align-items-center justify-content-center">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        <span>{`¿Estas seguro de que quieres ${processAction} ${summary.finalizados} audios? Se descartarán ${summary.errores} que dieron error.`}</span>
      </div>
    </Dialog>
  );
};
