import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import auditService from "../services/audit.service";

const { vnAuditorias: viewName, vfVerAuditorias: viewFunction } = viewTypes;
const userName = localStorage.getItem("userName");

const fetchAudit = async (filter) => {
  const response = await auditService.obtenerAuditorias(
    filter,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchAudit(filter) {
  return useQuery({
    queryKey: ["audit", filter],
    queryFn: () => fetchAudit(filter),
  });
}

// Fetch audit by id
const fetchAuditById = async (id) => {
  const response = await auditService.getAudit(
    id,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchAuditById(id) {
  return useQuery({
    queryKey: ["aud", id],
    queryFn: () => fetchAuditById(id),
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
/** */
