import { create } from "zustand";
import { AudioObject, TableData } from "../components/uploader/uploaderTypes";

interface AuditStore {
  audit: any | null;
  data: TableData[];
  audios: AudioObject[];
  setAudit: (audit: any) => void;
  setData: (data: TableData[]) => void;
  setAudios: (audios: AudioObject[]) => void;
  concurrencia: number;
  updateDataProps: (
    audioName: string,
    updates: { prop: keyof TableData; value: any }[]
  ) => void;
  deleteRowData: (id: string | number) => void;
  listos: number;
  total: number;
  setListos: (listos: number | ((prevListos: number) => number)) => void;
  setTotal: (total: number) => void;
  cleanAll: () => void;
  auditId: any | null;
  setAuditId: (auditId: any) => void;
  packageId: string;
}

const genUniqueId = () => {
  const timestamp = Date.now().toString(36);
  const random1 = Math.random().toString(36).substring(2, 10);
  const random2 = Math.random().toString(36).substring(2, 10);
  return `${timestamp}-${random1}-${random2}`;
};

export const useAuditStore = create<AuditStore>((set) => ({
  audit: null,
  setAudit: (audit) => set({ audit }),

  packageId: genUniqueId(),
  concurrencia: 3,

  data: [],
  audios: [],

  listos: 0,
  total: 0,

  setData: (data) => set({ data }),

  updateDataProps: (
    audioName: string,
    updates: { prop: keyof TableData; value: any }[]
  ) =>
    set((state) => {
      const findMatch = (audioName: string) => {
        return state.data.find((item) =>
          Object.values(item).some((itemValue) => itemValue === audioName)
        );
      };

      const matchedItem = findMatch(audioName);

      if (matchedItem) {
        const updatedItem = updates.reduce(
          (acc, { prop, value }) => {
            acc[prop] = value;
            return acc;
          },
          { ...matchedItem }
        );

        return {
          data: state.data.map((item) =>
            item === matchedItem ? updatedItem : item
          ),
        };
      }

      return state;
    }),

  deleteRowData: (id: string | number) =>
    set((state) => {
      const updatedData = state.data.filter((item) => item.id !== id);
      return { data: updatedData };
    }),

  setAudios: (audios) => set({ audios }),
  setListos: (listos) =>
    set((state) => ({
      listos: typeof listos === "function" ? listos(state.listos) : listos,
    })),
  setTotal: (total) => set({ total }),

  cleanAll: () =>
    set(() => ({
      audit: null,
      auditId: null,
      audios: [],
      data: [],
      total: 0,
      listos: 0,
      packageId: genUniqueId(),
    })),

  auditId: null,
  setAuditId: (auditId) => set({ auditId }),
}));
