import http from "../common/components/http-common";

class AuditService {
  obtenerAuditorias(
    filters,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: filters,
    };
    return http.get<any>("/v1/audit", config);
  }

  getAudit(id, userName: string, viewName: string, viewFunction: string) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.get("/v1/audit/" + id, config);
  }

  downloadFile(
    auditId: string,
    auditName: string,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    const headers = { userName, viewName, viewFunction };

    return http
      .request({
        url: `v1/audit/${auditId}/templateFile`,
        method: "get",
        headers: headers,
        responseType: "blob",
      })
      .then((response) => {
        if (!response || !response.data) {
          throw new Error("No se recibieron datos del servidor.");
        }

        const contentType = response.headers["content-type"];
        if (
          contentType !==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
          contentType !== "application/vnd.ms-excel"
        ) {
          throw new Error("El archivo recibido no es un archivo Excel válido.");
        }

        const blob = new Blob([response.data], { type: contentType });

        const contentDisposition = response.headers["content-disposition"];
        let fileName = `${auditName}_template.xlsx`;

        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (fileNameMatch && fileNameMatch[1]) {
            fileName = fileNameMatch[1].replace(/['"]/g, "");
          }
        }

        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();

        window.URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {
        console.error("Error descargando el archivo:", error);
      });
  }
}

export default new AuditService();
