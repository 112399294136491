import React from "react";
import { Button, Column, DataTable, Toolbar } from "primereact";
import { styleEstado } from "../utils/option.util";
import { FilterPanel } from "./FilterPanel";

export const AuditTable = ({
  openEdit,
  openDetail,
  getFile,
  customers,
  footerPage,
  loading,
  lazyParams,
  setLazyParams,
  initParams,
}) => {
  const onSort = (event) => {
    setLazyParams((previousState) => {
      return {
        ...previousState,
        sortBy: event.sortField,
        direction: event.sortOrder,
      };
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="my-2">
        <div className="fontToolClient">Listado de Speech Analytics</div>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return <></>;
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <span
        className={
          "customer-badge status-" +
          styleEstado(rowData.active) +
          " text-status"
        }
      >
        {rowData.active ? "Activo" : "Inactivo"}
      </span>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-file-edit"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => openEdit(rowData)}
          disabled={!rowData.active}
        />

        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => openDetail(rowData)}
        />

        <Button
          icon="pi pi-download"
          className="p-button-rounded p-button-info"
          onClick={() => getFile(rowData)}
          disabled={!rowData.active}
        />
      </div>
    );
  };

  return (
    <>
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      <FilterPanel
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        initParams={initParams}
      />

      <DataTable
        value={customers}
        responsiveLayout="stack"
        dataKey="id"
        stripedRows
        onSort={onSort}
        sortField={lazyParams.sortBy}
        sortOrder={lazyParams.direction}
        loading={loading}
        emptyMessage="Sin resultados"
        footer={footerPage}
      >
        <Column
          field="name"
          showFilterMenu={false}
          sortable
          header="Nombre"
          alignHeader="left"
        />
        <Column
          field="active"
          sortable
          showFilterMenu={false}
          header="Estado"
          alignHeader="center"
          bodyClassName={"centerRowData"}
          body={statusBodyTemplate}
        />
        <Column
          bodyStyle={{ textAlign: "end", justifyContent: "end" }}
          body={actionBodyTemplate}
          exportable={false}
        />
      </DataTable>
    </>
  );
};
